import axios from '../uitls/require';

export default {
    /**
     *fetchList
     * @param {*} data 入参
     * @return {*}
     */
    fetchList(data) {
        return axios.post('/admin/subscription/list', data);
    },
    /**
     *fetchList
     * @param {*} data 入参
     * @return {*}
     */
    createdCode(data) {
        return axios.post('/admin/subscription/add-code', data);
    },
    /**
     * 订阅码发送邮件
     * @param {*} data 入参
     * @returns {*}
     */
    sendEmail(data) {
        return axios.post('/admin/subscription/send-email', data);
    },
    /**
     * 获取用户信息
     * @param {Object} params 入参
     * @returns {*}
    */
    customerInfo(params = {}) {
        return axios.get('/admin/subscription/customer', { params });
    }
};
