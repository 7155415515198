import input from '@/uitls/inputCheck';

export const selectType = [
    {
        value: 1,
        label: '个人版'
    },
    {
        value: 2,
        label: '专业版'
    }
];

// /**
//  *checkBalanceNumber
//  * @param {*} rule rule
//  * @param {*} value value
//  * @param {*} callback callback
//  */
// const checkIsNumber = (rule, value, callback) => {
//     setTimeout(() => {
//         const res = input.checkIsNumber(value);
//         if (res !== -1) {
//             callback(input.checkFormErrorMsg(res));
//         }
//         if (value <= 0) {
//             callback('请输入大于零的数');
//         }
//         if (value > 200) {
//             callback('请输入小于200的数');
//         }
//         callback();
//     }, 200);
// };
/**
 *checkBalance
 * @param {*} rule rule
 * @param {*} value value
 * @param {*} callback callback
 */
const checkBalance = (rule, value, callback) => {
    setTimeout(() => {
        const reg = /^[0-9]*$/;
        if (!reg.test(value)) {
            callback(new Error('请输入整数'));
        }
        if (value < 0) {
            callback('请输入大于零的数');
        }
        if (value > 100) {
            callback('请输入小于100的数');
        }
        callback();
    }, 200);
};

/**
 *checkDuration
 * @param {*} rule rule
 * @param {*} value value
 * @param {*} callback callback
 */
const checkDuration = (rule, value, callback) => {
    setTimeout(() => {
        const res = input.checkIsNumber(value);
        if (res !== -1) {
            callback(input.checkFormErrorMsg(res));
        }
        if (value <= 0) {
            callback('请输入大于零的数');
        }
        if (value > 75) {
            callback('请输入小于75的数');
        }
        callback();
    }, 200);
};

export const addRules = {
    type: [{
        required: true,
        message: '请选择订阅版本',
        trigger: 'change'
    }],
    failure_at: [{
        required: true,
        message: '请选择日期',
        trigger: 'blur'
    }],
    duration: [{
        required: true,
        message: '请输入时长',
        trigger: 'blur'
    }, {
        validator: checkDuration,
        trigger: 'blur'
    }],
    balance: [{
        required: false,
        validator: checkBalance,
        trigger: 'blur'
    }]
    // total: [{
    //     required: true,
    //     message: '请输入生成数量',
    //     trigger: 'blur'

    // }, {
    //     validator: checkIsNumber,
    //     trigger: 'blur'
    // }]
};
