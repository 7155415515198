<template>
    <div class="aops-add-subscribecode">
        <div class="aop-back" @click="handleBack">
            <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
        </div>
        <div class="aop-add-title">新增订阅码</div>
        <addForm
            ref="addForm"
            :form="addForm"
            :user-num="selectCustomers.length"
        />
        <selectCustomers
            :select-customers.sync="selectCustomers"
            :quantity="addForm.total"
            :max-quantity="200"
            type="subscribe"
        />
        <div class="aop-add-footer">
            <el-button type="primary" class="aop-confirm-button" :loading="btnLoading"  @click="handleSubmit">确认</el-button>
            <el-button class="cancel-button" @click="handleBack">取 消</el-button>
        </div>
  </div>
</template>

<script>
import api from '@/api/subscription';
import addForm from './form/form.vue';
import selectCustomers from '@/pages/_common/select_customers/select_customers.vue';

export default {
    components: { addForm, selectCustomers },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            addForm: {
                type: '',
                failure_at: '',
                duration: '',
                balance: '',
                total: 1
            },
            selectCustomers: [],
            btnLoading: false
        };
    },
    computed: {},
    methods: {
        /**
         * 返回
        */
        handleBack() {
            this.$router.push('/subscribe/code');
        },
        /**
         * 点击确认按钮
        */
        handleSubmit() {
            this.$refs.addForm.$refs.form.validate((valid) => {
                if (valid) {
                    this.addCode();
                }
            });
        },
        /**
         * 新增订阅码
        */
        addCode() {
            const data = {
                ...this.addForm,
                balance: this.addForm.balance || 0,
                failure_at: this.addForm.failure_at / 1000,
                customer_ids: this.selectCustomers.map((item) => item.id)
            };
            this.btnLoading = true;
            api.createdCode(data).then((res) => {
                if (res.data.code === 10200) {
                    this.$$success('该订阅码创建成功');
                    this.handleBack();
                } else {
                    this.$$error(res.data.message);
                }
                this.btnLoading = false;
            }, () => {
                this.btnLoading = false;
            });
        }
    }
};
</script>
<style lang="less">
@import "./index.less";
</style>
