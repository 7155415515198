<template>
    <el-form
        ref="form"
        class="aops-subscribe-add-form"
        :model="form"
        label-width="80px"
        label-position="left"
        :rules="rules"
        inline
    >
        <el-form-item label="订阅版本" prop="type">
            <el-select v-model="form.type">
                <el-option v-for="code in selectType" :key="code.value" :label="code.label" :value="code.value"/>
            </el-select>
        </el-form-item>
        <el-form-item label="失效日期" prop="failure_at">
            <el-date-picker
                v-model="form.failure_at"
                type="date"
                :picker-options="pickerOptions"
                value-format="timestamp"
                :clearable="false"
                placeholder="选择日期"
            />
        </el-form-item>
        <el-form-item label="赠送时长" prop="duration">
            <el-input v-model="form.duration" placeholder="0" />
            <span class="aop-day">天</span>
        </el-form-item>
        <el-form-item label="包含积分" prop="balance">
            <el-input v-model="form.balance" placeholder="0" />
        </el-form-item>
        <el-form-item label="生成数量" prop="total">
            <el-input-number
                v-model="form.total"
                placeholder="请输入生成数量"
                :min="1"
                :max="200"
                :controls="false"
            />
        </el-form-item>
        <div class="aop-send-num">
            发送邮件数<span v-dompurify-html="'\u00a0\u00a0\u00a0\u00a0'" />{{userNum}}/{{form.total}}
        </div>
    </el-form>
</template>

<script>
import { toDate, formatDate } from 'ecoplants-lib';
import { selectType, addRules } from '../data.js';

export default {
    props: {
        userNum: {
            type: Number,
            default: 0
        },
        form: {
            type: Object,
            default: () => ({})
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        /**
         * 生成数量校验
         * @param {*} rule 规则
         * @param {*} value 值
         * @param {*} callback 返回
        */
        const totalValidator = (rule, value, callback) => {
            if (Number(value) < this.userNum) {
                callback('生成数量应不小于已选择用户数');
            } else {
                callback();
            }
        };
        return {
            selectType,
            rules: {
                ...addRules,
                total: [
                    { required: true, message: '请输入生成数量', trigger: 'blur' },
                    { validator: totalValidator, trigger: 'blur' }
                ]
            }
        };
    },
    computed: {
        /**
         * 有效截止日期选择器配置
         * @returns {*}
        */
        pickerOptions() {
            const pickerOptions = {
                /**
                 * 禁用日期 选择范围为30天内
                 * @param {*} time 日期
                 * @returns {Boolean}
                */
                disabledDate(time) {
                    const max = toDate(formatDate(new Date(), 'yyyy-MM-dd')).getTime() + 30 * 24 * 60 * 60 * 1000;
                    return time.getTime() < Date.now() || time.getTime() > max;
                }
            };
            return pickerOptions;
        }
    },
    methods: {}
};
</script>
<style lang="less">
@import "./form.less";
</style>
